<template>
  <img
    :src="loader"
    class="opacity-7 loading"
    alt="loading"
  >
</template>

<script>
import loader from '@/assets/img/content-load.gif'

export default {
  name: 'ContentPlaceholder',
  data() {
    return {
      loader
    }
  }
}
</script>

<style>
.loading {
  margin: 10px auto;
  height: 100px;
  width: auto;
  padding: 20px;
}
</style>

