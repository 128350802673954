import axios from 'axios'
import store from '@/store'

const apiUrl = process.env.VUE_APP_API_URL


const apiPublicUrl = `${apiUrl}public`
const apiPrivateUrl = `${apiUrl}private`
const apiAdminUrl = `${apiUrl}admin`

const publicClient = axios.create({ baseURL: apiPublicUrl })

let adminClient = axios.create({ baseURL: apiAdminUrl })
adminClient.interceptors.request.use((config) => setBearer(config, store.getters.getToken))

let privateClient = axios.create({ baseURL: apiPrivateUrl })
privateClient.interceptors.request.use((config) => setBearer(config, store.getters.getToken))

const setBearer = (config, token) => {
  config.headers['Authorization'] = 'Bearer ' + token
  return config
}

export {
  publicClient,
  adminClient,
  privateClient
}
