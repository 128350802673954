import { privateClient } from '@/plugins/axios'
import { buildUrlParams } from '@/utils/urlUtils'

export default class privateMenuSectionResource {
    static async createMenuSection (menuSection) {
        try {
            return privateClient.post('/menu-section', menuSection)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateMenuSection (menuSection) {
        try {
            return privateClient.patch(`/menu-section/${menuSection.id}`, menuSection)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getMenuMenuSections (menuId, params) {
        try {
            const taverns = await privateClient.get(`/menu/${menuId}/menu-sections`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteMenuSection (menuSectionId) {
        try {
            await privateClient.delete(`/menu-section/${menuSectionId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
