import { privateClient} from '@/plugins/axios'

export default class PrivateUserResource {
  static async createUser (user) {
    try {
      return privateClient.post('/auth/signup', user)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async updateUser (user) {
    try {
      return privateClient.patch(`/user/${user.id}`, user)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async deleteUser (userId) {
    try {
      return privateClient.delete(`/user/${userId}`)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async getUser (userId) {
    try {
      const response = await privateClient.get(`/user/${userId}`)
      return response.data
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async signin (login) {
    try {
      const response = await privateClient.post('/auth/signin', login)
      return response.data
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async recoverPassword (request) {
    try {
      await privateClient.post('/auth/reset-password-request', request)
      return 'messages.successMessage'
    } catch (error) {
      if (error.response.status === 409) {
        return 'messages.pendingRequest'
      }
      console.warn(error.response)
      return null
    }
  }

  static async resetPassword (request) {
    try {
      await privateClient.post('/auth/reset-password', request)
      return true
    } catch (error) {
      console.warn(error)
      return null
    }
  }

}
