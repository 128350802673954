import {adminClient} from '@/plugins/axios'
import {buildUrlParams} from '@/utils/urlUtils'

export default class adminUserResource {

    static async createUser(user) {
        try {
            return adminClient.post('/auth/signup', user)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateUser(user) {
        try {
            return adminClient.patch(`/user/${user.id}`, user)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteUser(userId) {
        try {
            return adminClient.delete(`/user/${userId}`)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async signin(login) {
        try {
            const response = await adminClient.post('/auth/signin', login)
            return response.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getUser(userId) {
        try {
            const response = await adminClient.get(`/user/${userId}`)
            return response.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getUsers(params) {
        try {
            const users = await adminClient.get('/user/', {params: buildUrlParams(params)})
            return {data: users.data.data, total: users.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
