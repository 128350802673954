<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <TimeTableList :tavern-id="tavernId" />
  </main>
</template>

<script>
import TimeTableList from '@/views/components/TimeTable/TimeTableList.vue'

export default {
  name: 'TimeTablesPage',
  components: {
    TimeTableList,
  },
  data () {
    const tavernId = this.$route.params?.tavernId ?  this.$route.params?.tavernId : null
    return {
      tavernId
    }
  }
}
</script>
