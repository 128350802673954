<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <contentPlaceholder v-if="loading" />
    <UpsertUser
      v-else
      :user="user"
      @update-user="updateUser"
    />
  </main>
</template>

<script>
import adminUserResource from '@/resources/user/adminUserResource'
import PrivateUserResource from '@/resources/user/privateUserResource'
import contentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import UpsertUser from '@/views/components/user/UpsertUser.vue'

export default {
  name: 'ProfilePage',
  components: {
    UpsertUser,
    contentPlaceholder
  },
  data () {
    return {
      loading: true,
      user: null
    }
  },
  mounted () {
    const userId = this.$route.params?.userId ?  this.$route.params?.userId : null
    this.resource = this.admin ? adminUserResource : PrivateUserResource
    this.selectUser(userId)
  },
  methods: {
    async selectUser(userId) {
      const response = await this.resource.getUser(userId)
      if (response) {
        this.loading = false
        this.user = response
      }
    },
    async updateUser (userData) {
      try {
        const response = await this.resource.updateUser(userData)
        if (!response) {
          this.$notice.alert.error(this.$t('messages.errorMessage'))
          return false
        }
        this.$notice.toast.info(this.$t('messages.updateMessage'))
      } catch (e) {
        this.$notice.alert.error(this.$t('messages.errorMessage'))
        console.warn(e)
      }
    },
  }
}
</script>
