<template>
  <main class="mt-0 main-content">
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-gray mb-1 mt-5 pt-5">
              {{ $t('messages.welcome') }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-1">
              <h5>{{ $t('messages.signIn') }}</h5>
            </div>
            <div class="card-body">
              <ValidationForm
                v-slot="{errors}"
                :validation-schema="schema"
                type="POST"
                @submit="loginUser"
              >
                <div class="form-group">
                  <Field
                    name="email"
                    type="email"
                    class="form-control"
                    :class="{'is-invalid':errors.email}"
                    :placeholder="$t('messages.email')"
                    aria-label="Email"
                  />
                  <ErrorMessage
                    class="text-danger small"
                    name="email"
                  />
                </div>
                <div class="form-group">
                  <Field
                    name="password"
                    type="password"
                    class="form-control"
                    :class="{'is-invalid':errors.password}"
                    :placeholder="$t('messages.password')"
                    aria-label="Password"
                  />
                  <ErrorMessage
                    class="text-danger small"
                    name="password"
                  />
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    role="button"
                    class="my-4 mb-2 btn btn-primary w-100"
                  >
                    {{ $t('messages.signIn') }}
                  </button>
                </div>
              </ValidationForm>
              <p class="text-center text-sm mt-2">
                <a
                  href="/request-reset"
                  class="text-primary"
                >{{ $t('messages.forgotPassword') }}</a>
              </p>
              <div class="px-1 pt-0 text-center card-footer px-lg-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
  import{ object, string } from 'yup'

  export default {
    name: 'SigninForm',
    components: { ValidationForm, Field, ErrorMessage },
    emits: ['signin-user'],
    data() {
      const schema = object({
        email: string().required(this.$t('messages.errors.requiredField'))
            .email(this.$t('messages.errors.fieldMustBeType', { type: 'email' })),
        password: string().required(this.$t('messages.errors.requiredField'))
      })
      return {
        schema
      }
    },
    methods: {
      async loginUser(loginData) {
        const login = {
          email: loginData.email,
          password: loginData.password
        }
        this.$emit('signin-user', login)
      }
    }
  }
  </script>
