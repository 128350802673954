<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <MenuSectionList :menu-id="menuId" />
  </main>
</template>

<script>
import MenuSectionList from '@/views/components/menuSection/MenuSectionList.vue'

export default {
  name: 'MenuSectionsPage',
  components: {
    MenuSectionList
  },
  data () {
    const menuId = this.$route.params?.menuId ?  this.$route.params?.menuId : null
    return {
      menuId
    }
  }
}
</script>
