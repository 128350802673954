<template>
  <ul
    role="menubar"
    aria-disabled="false"
    aria-label="Pagination"
    class="pagination"
  >
    <li>
      <button
        :disabled="value <= 1"
        :aria-label="$t('words.previous')"
        type="button"
        role="menuitem"
        @click.prevent="prev()"
      >
        ‹
      </button>
    </li>
    <li
      :class="{'sr-only': !showLabel}"
      aria-live="polite"
    >
      {{ $t('messages.pageNumberFromTotal', { page: value, pages: pages }) }}
    </li>
    <li>
      <button
        :disabled="value >= pages"
        :aria-label="$t('words.next')"
        role="menuitem"
        type="button"
        @click.prevent="next()"
      >
        ›
      </button>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'ListPagination',
  mixins: [],
  props: {
    value: { type: Number, required: true },
    totalRows: { type: Number, required: true },
    perPage: { type: Number, required: true },
    showLabel: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
  },
  emits: ['set-page'],
  computed: {
    pages () {
      return Math.ceil(this.totalRows / this.perPage)
    }
  },
  methods: {
    prev () {
      this.$emit('set-page', this.value - 1)
    },
    next () {
      this.$emit('set-page', this.value + 1)
    }
  }
}
</script>

<style scoped>
ul{
  height: 30px;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 0px;
}
button {
  border: none;
  background: transparent;
  height: 30px;
  width: 30px;
  line-height: 0;
  padding-bottom: 8px;
  font-size: 31px;
  color: var(--dark);
}
button[disabled]{
  color: #b2b2b2;
}
.b-skeleton{
  margin-left: 15px;
  margin-top: 6px;
}
</style>
