<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="user.id">
        {{ $t('messages.editUser') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createUser') }}
      </h6>
    </div>
    <div class="card-body">
      <ValidationForm
        v-slot="{errors}"
        :validation-schema="userSchema"
        type="POST"
        @submit="upsertUser"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.name') }}</label>
          <Field
            v-model="computedUserName"
            name="name"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.userName}"
            :placeholder="$t('messages.name')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div
          v-if="!user.id"
          class="form-group"
        >
          <label class="form-label">{{ $t('messages.password') }}</label>
          <Field
            name="password"
            type="password"
            class="form-control"
            :class="{'is-invalid':errors.userPassword}"
            :placeholder="$t('messages.password')"
            aria-label="Password"
          />
          <ErrorMessage
            class="text-danger small"
            name="password"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.email') }}</label>
          <Field
            v-model="computedUserEmail"
            name="email"
            type="email"
            class="form-control"
            :value="user.email"
            :class="{'is-invalid':errors.userEmail}"
            :placeholder="$t('messages.email')"
            :disabled="!admin"
            aria-label="Email"
          />
          <ErrorMessage
            class="text-danger small"
            name="email"
          />
        </div>
        <div
          v-if="admin"
          class="form-group"
        >
          <label class="form-label">{{ $t('messages.userStatus') }}</label>
          <Field
            v-model="computedUserStatus"
            name="status"
            as="select"
            class="form-control"
            :value="user.status"
            :class="{'is-invalid':errors.userStatus}"
            :placeholder="$t('messages.userStatus')"
            :disabled="!admin"
            aria-label="Status"
          >
            <option
              v-for="item in statusList"
              :key="item"
              :value="item"
            >
              {{ $t('messages.status.' + item) }}
            </option>
          </Field>
          <ErrorMessage
            class="text-danger small"
            name="status"
          />
        </div>
        <div class="row">
          <label>{{ $t('messages.createdDate') }}: <span class="fw-light">{{ user.createdAt }}</span></label>
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              type="submit"
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              &nbsp;&nbsp;{{ $t('messages.save') }}
            </button>

            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </ValidationForm>
    </div>
  </div>
</template>

<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import { object, string } from 'yup'
import { UserStatus, UserType } from '@/utils/enums/userEnum.js'
import User from '@/models/user.js'

export default {
  name: 'UpsertUser',
  components: { ValidationForm, Field, ErrorMessage },
  props: { user: User },
  emits: ['create-user', 'update-user', 'hide-forms'],
  data () {
    let fieldsValidation = {
      name: string().required(this.$t('messages.errors.requiredField')),
      email: string().required(this.$t('messages.errors.requiredField'))
          .email(this.$t('messages.errors.fieldMustBeType', { type: 'email' })),
      status: string().optional()
    }

    if (!this.user.id) {
      fieldsValidation.password = string().required(this.$t('messages.errors.requiredField'))
          .min(7, this.$t('messages.errors.minLength', { n: 7}))
    }
    const userSchema = object(fieldsValidation)

    return {
      userSchema,
      statusList: UserStatus,
      typeList: UserType
    }
  },
  computed: {
    computedUserEmail: {
      get: function () {
        return this.user.email
      },
      set: function (email) {
        return email
      }
    },
    computedUserName: {
      get: function () {
        return this.user.name
      },
      set: function (name) {
        return name
      }
    },
    computedUserStatus: {
      get: function () {
        return this.user.status
      },
      set: function (status) {
        return status
      }
    }
  },
  methods: {
    async upsertUser (userData) {
      if (!this.user.id) {
        await this.createUser(userData)
        return
      }
      await this.updateUser(userData)
    },
    async createUser(userData) {
      let user = {
        email: userData.email,
        password: userData.password,
        name: userData.name,
        status: userData.status,
        type: userData.type
      }
      this.$emit('create-user', user)
    },
    async updateUser (userData) {
      let user = {
        id: this.user.id,
        email: userData.email,
        name: userData.name,
        status:  this.admin ? userData.status : this.user.status,
        type: this.admin ? userData.type : this.user.type
      }
      this.$emit('update-user', user)
    },
    cancel () {
      this.$emit('hide-forms')
    }
  }
}
</script>
