<template>
  <RecoverPasswordForm
    v-if="!isLogged()"
  />
</template>

<script>

import PrivateUserResource from '@/resources/user/privateUserResource'
import { mapGetters } from 'vuex'
import RecoverPasswordForm from '@/views/components/recoverPassword/RecoverPasswordForm.vue'

export default {
  name: 'RecoverPassword',
  components: { RecoverPasswordForm }
}
</script>
