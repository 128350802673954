<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="tavern.id">
        {{ $t('messages.editTavern') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createTavern') }}
      </h6>
    </div>
    <div class="card-body">
      <ValidationForm
        v-slot="{errors}"
        :validation-schema="tavernSchema"
        type="POST"
        @submit="upsertTavern"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.tavernName') }}</label>
          <Field
            v-model="tavernTitle"
            name="name"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.tavernName}"
            :placeholder="$t('messages.tavernName')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.tavernSlug') }}</label>
          <Field
            v-model="tavernSlug"
            name="slug"
            type="text"
            class="form-control"
            :disabled="tavern.slug"
            :class="{'is-invalid':errors.tavernSlug}"
            :placeholder="$t('messages.tavernSlug')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="slug"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.tavernDescription') }}</label>
          <TextEditor
            ref="description"
            :content="tavern.description"
          />
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              type="submit"
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              {{ $t('messages.save') }}
            </button>
            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </ValidationForm>
    </div>
  </div>
</template>
<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import {lazy, object, string} from 'yup'
import TextEditor from '@/components/common/TextEditor.vue'
import Tavern from '@/models/tavern'

export default {
  name: 'UpsertTavern',
  components: { ValidationForm, ErrorMessage, Field, TextEditor },
  props: {
    tavern: Tavern,
    userId: { type: String, required: true }
  },
  emits: ['create-tavern', 'update-tavern', 'hide-forms'],
  data () {
    const tavernSchema = object({
      name: string().required(this.$t('messages.errors.requiredField')),
      slug: lazy((value) => {
        if (!value || value === '') {
          return string().optional().nullable()
        }
        return string().matches(/^[a-z0-9-_]+$/, { message: this.$t('messages.errors.alphaDash') })
      })
    })
    return {
      tavernSchema
    }
  },
  computed: {
    tavernTitle: {
      get: function () {
        return this.tavern.title
      },
      set: function (title) {
        return title
      }
    },
    tavernSlug: {
      get: function () {
        return this.tavern.slug
      },
      set: function (slug) {
        return slug
      }
    }
  },
  methods: {
    async upsertTavern (tavernData) {
      if (!this.tavern.id) {
        await this.createTavern(tavernData)
        return
      }
      await this.updateTavern(tavernData)
    },
    async createTavern (tavernData) {
      let tavern = {
        userId: this.userId,
        slug: tavernData.slug,
        title: tavernData.name,
        description: this.$refs.description.getContent(),
        faviconImage: null,
        logoImage: null,
        headerImage: null,
        template: null,
      }
      this.$emit('create-tavern', tavern)
    },
    async updateTavern (tavernData) {
      let tavern = {
        id: this.tavern.id,
        slug: tavernData.slug,
        title: tavernData.name,
        description: this.$refs.description.getContent()
      }
      this.$emit('update-tavern', tavern)
    },
    cancel () {
      this.$emit('hide-forms')
    }
  }
}
</script>