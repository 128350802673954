import { adminClient } from '@/plugins/axios'

export default class adminContactResource {
  static async createContact (contact) {
    try {
      return adminClient.post('/contact', contact)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async updateContact (contact) {
    try {
      return adminClient.patch(`/contact/${contact.id}`, contact)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async getTavernContact (tavernId) {
    try {
      const contact = await adminClient.get(`/tavern/${tavernId}/contact`)
      return contact.data
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async deleteContact (contactId) {
    try {
      await adminClient.delete(`/contact/${contactId}`)
      return true
    } catch (error) {
      console.warn(error)
      return null
    }
  }
}
