export default class Tavern {
    constructor (data) {
        if (!data) {
            this.id = null
            this.userId = null
            this.slug = null
            this.title = null
            this.description = null
            this.faviconImage = null
            this.logoImage = null
            this.headerImage = null
            this.template = null
            this.createdAt = null
            this.status = null
            return
        }
        this.id = data.id
        this.userId = data.userId
        this.slug = data.slug
        this.title = data.title
        this.description = data.description
        this.faviconImage = data.faviconImage
        this.logoImage = data.logoImage
        this.headerImage = data.headerImage
        this.template = data.template
        this.createdAt = data.createdAt
        this.status = data.status
    }
}
