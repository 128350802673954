import { privateClient } from '@/plugins/axios'
import { buildUrlParams } from '@/utils/urlUtils'

export default class adminMenuItemResource {
    static async createMenuItem (menuItem) {
        try {
            return privateClient.post('/menu-item', menuItem)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateMenuItem (menuItem) {
        try {
            menuItem.price = menuItem.price ? menuItem.price.toString() !== '' ? menuItem.price.toString() : null: null
            return privateClient.patch(`/menu-item/${menuItem.id}`, menuItem)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getMenuSectionMenuItems (menuSectionId, params) {
        try {
            const taverns = await privateClient.get(`/menu-section/${menuSectionId}/menu-items`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteMenuItem (menuItemId) {
        try {
            await privateClient.delete(`/menu-item/${menuItemId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
