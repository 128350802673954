import { adminClient } from '@/plugins/axios'
import { buildUrlParams } from '@/utils/urlUtils'

export default class adminTimeTableResource {
  static async createTimeTable (timeTable) {
    try {
      return adminClient.post('/time-table', timeTable)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async updateTimeTable (timeTable) {
    try {
      return adminClient.patch(`/time-table/${timeTable.id}`, timeTable)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async deleteTimeTable (timeTableId) {
    try {
      await adminClient.delete(`/time-table/${timeTableId}`)
      return true
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async getTavernTimeTables (tavernId, params) {
    try {
      const timeTables = await adminClient.get(`/tavern/${tavernId}/time-tables`, { params: buildUrlParams(params) })
      return timeTables.data
    } catch (error) {
      console.warn(error)
      return null
    }
  }
}
