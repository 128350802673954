<template>
  <main class="mt-0 main-content">
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg">
      <div class="container">
        <div class="row justify-content-center" />
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h4>{{ $t('messages.recoverPassword') }}</h4>
            </div>
            <div class="card-body">
              <ValidationForm
                v-if="!requested"
                v-slot="{errors}"
                :validation-schema="schema"
                type="POST"
                @submit="sendRequest"
              >
                <div class="form-group">
                  <Field
                    name="email"
                    type="email"
                    class="form-control"
                    :class="{'is-invalid':errors.email}"
                    :placeholder="$t('messages.enterEmail')"
                    aria-label="Email"
                  />
                  <ErrorMessage
                    class="text-danger small"
                    name="email"
                  />
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    role="button"
                    class="my-4 mb-2 btn btn-primary w-100"
                  >
                    {{ $t('messages.send') }}
                  </button>
                </div>
              </ValidationForm>
              <div v-else>
                <p>{{ $t('messages.passwordRecoverRequested') }}</p>
              </div>
              <div class="px-1 pt-0 text-center card-footer px-lg-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import{ object, string } from 'yup'
import {mapGetters} from 'vuex'
import PrivateUserResource from '@/resources/user/privateUserResource'

export default {
  name: 'RecoverPasswordForm',
  components: { ValidationForm, Field, ErrorMessage },
  emits: ['recover-password'],
  data() {
    const schema = object({
      email: string().required(this.$t('messages.errors.requiredField'))
            .email(this.$t('messages.errors.fieldMustBeType', { type: 'email' }))
    })
    return {
      schema,
      requested: false
    }
  },
  methods: {
    ...mapGetters(['isLogged']),
    async sendRequest(requestData) {
      const recoverData = {
        email: requestData.email
      }
      const msg = await PrivateUserResource.recoverPassword(requestData)

      if (!msg) {
        this.$notice.alert.error(this.$t('messages.errorMessage'));
        return
      }

      this.$notice.alert.success(this.$t(msg))

      this.requested = true
    }
  }
}
</script>
