import TagStatus from '@/utils/enums/tagEnum'

export default class Tag {
    constructor (data) {
        if (!data) {
            this.id = null
            this.name = null
            this.description = null
            this.className = null
            this.status = TagStatus.inactive
            return
        }
        this.id = data.id
        this.name = data.name
        this.description = data.description
        this.className = data.className
        this.status = data.status
    }
}
