export default class TimeTable {
  constructor (data) {
    if (!data) {
      this.id = null
      this.tavernId = null
      this.day = null
      this.dayPosition = null
      this.status = null
      this.from = TimeTable.parseHour(null)
      this.to = TimeTable.parseHour(null)
      this.createdAt = null
      return
    }

    this.id = data.id
    this.tavernId = data.tavernId
    this.day = data.day
    this.dayPosition = data.dayPosition
    this.status = data.status
    this.from = TimeTable.parseHour(data.from)
    this.to = TimeTable.parseHour(data.to)
    this.createdAt = data.createdAt
  }

  static parseHour (time) {
    let parsedTime = {
      hour: null,
      quarter: null
    }
    if (time) {
      const parts = time.split(':')
      parsedTime = { hour: parts[0], quarter: parts[1] }
    }

    return parsedTime
  }

  static constructHour (hour, quarter) {
    if (!hour && !quarter) {
      return null
    }
    if (!hour && quarter) {
      return null
    }
    if (!quarter) {
      return hour
    }
    return [hour, quarter].join(':')
  }
}

