<template>
  <QuillEditor
    ref="editor"
    v-model:content="computedContent"
    content-type="html"
    theme="snow"
  />
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  name: 'TextEditor',
  components: { QuillEditor },
  props: {
    content: { type: String, required: false, default: null }
  },
  computed: {
    computedContent: {
      get: function () {
        return this.content
      },
      set: function (description) {
        return description
      }
    }
  },
  methods: {
    getContent() {
      const text = this.$refs.editor.getContents()
      return this.isEmpty(text) ? null : text
    },
    isEmpty(text) {
      console.log({ text })
      return text.replace(/<(.|\n)*?>/g, '').trim().length === 0
    }
  }
}

</script>
