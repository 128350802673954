import { adminClient } from '@/plugins/axios'
import { buildUrlParams } from '@/utils/urlUtils'

export default class privateMenuResource {
    static async createMenu (menu) {
        try {
            return adminClient.post('/menu', menu)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateMenu (menu) {
        try {
            return adminClient.patch(`/menu/${menu.id}`, menu)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getTavernMenus (tavernId, params) {
        try {
            const taverns = await adminClient.get(`/tavern/${tavernId}/menus`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteMenu (menuId) {
        try {
            await adminClient.delete(`/menu/${menuId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
