import {adminClient} from '@/plugins/axios'
import {buildUrlParams} from '@/utils/urlUtils'

export default class adminTagResource {
    static async createTag (tag) {
        try {
            return adminClient.post('/tag', tag)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateTag (tag) {
        try {
            return adminClient.patch(`/tag/${tag.id}`, tag)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteTag (tagId) {
        try {
            await adminClient.delete(`/tag/${tagId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getMenuItemTags (menuItemId) {
        try {
            const tags = await adminClient.get(`/menu-item/${menuItemId}/tags`)
            return tags.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getTags(params) {
        try {
            const tags = await adminClient.get('/tag/', { params: buildUrlParams(params) })
            return {data: tags.data.data, total: tags.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
