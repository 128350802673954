<template>
  <ul class="navbar-nav">
    <li class="nav-item">
      <sideNavItem
        :url="`/${baseDir}/users`"
        :nav-text="$t('messages.users')"
      >
        <template #icon>
          <mdicon
            name="account-box-multiple-outline"
            class="text-success"
          />
        </template>
      </SideNavItem>
    </li>
    <li class="nav-item">
      <sideNavItem
        :url="`/${baseDir}/taverns`"
        :nav-text="$t('messages.taverns')"
      >
        <template #icon>
          <mdicon
            name="store-outline"
            class="text-success"
          />
        </template>
      </SideNavItem>
    </li>
    <li class="nav-item">
      <sideNavItem
        :url="`/${baseDir}/tags`"
        :nav-text="$t('messages.tags')"
      >
        <template #icon>
          <mdicon
            name="tag-multiple-outline"
            class="text-success"
          />
        </template>
      </SideNavItem>
    </li>
  </ul>
</template>

<script>
import SideNavItem from '@/components/layout/sideNav/SideNavItem.vue'

export default {
  name: 'PrivateSideNav',
  components: { SideNavItem }
}
</script>

<style>

</style>
