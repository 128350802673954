const TimeTableStatus = {
  open : 'OPEN',
  closed: 'CLOSED'
}

const TimeTableDay = {
  monday: 'MONDAY',
  tuesday: 'TUESDAY',
  wednesday: 'WEDNESDAY',
  thursday: 'THURSDAY',
  friday: 'FRIDAY',
  saturday: 'SATURDAY',
  sunday: 'SUNDAY'
}

const TimeTableDayOrder = {
  'MONDAY': 0,
  'TUESDAY': 1,
  'WEDNESDAY': 2,
  'THURSDAY': 3,
  'FRIDAY': 4,
  'SATURDAY': 5,
  'SUNDAY': 6
}

const TimeTableHour = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']

const TimeTableQuarter = ['00', '15', '30', '45']

export { TimeTableStatus, TimeTableDay, TimeTableDayOrder, TimeTableHour, TimeTableQuarter }
