import { privateClient } from '@/plugins/axios'

export default class adminAddressResource {
  static async createContact (contact) {
    try {
      return privateClient.post('/contact', contact)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async updateContact (contact) {
    try {
      return privateClient.patch(`/contact/${contact.id}`, contact)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async getTavernContact (tavernId) {
    try {
      const contact = await privateClient.get(`/tavern/${tavernId}/contact`)
      return contact.data
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  static async deleteContact (contactId) {
    try {
      await privateClient.delete(`/contact/${contactId}`)
      return true
    } catch (error) {
      console.warn(error)
      return null
    }
  }
}
