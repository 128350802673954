<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <TavernList />
  </main>
</template>

<script>
import TavernList from '@/views/components/tavern/TavernList.vue'

export default {
  name: 'TavernsPage',
  components: {
    TavernList
  }
}
</script>
