import { adminClient } from '@/plugins/axios'
import {buildUrlParams} from '@/utils/urlUtils'

export default class adminTavernResource {
    static async createTavern (tavern) {
        try {
            return adminClient.post('/tavern', tavern)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateTavern (tavern) {
        try {
            return adminClient.patch(`/tavern/${tavern.id}`, tavern)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteTavern (tavernId) {
        try {
            await adminClient.delete(`/tavern/${tavernId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getUserTaverns (userId, params) {
        try {
            const taverns = await adminClient.get(`/user/${userId}/taverns`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getTaverns(params) {
        try {
            const users = await adminClient.get('/tavern/', { params: buildUrlParams(params) })
            return {data: users.data.data, total: users.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
