export default class Contact {
  constructor (data) {
    if (!data) {
      this.id = null
      this.tavernId = null
      this.address = null
      this.state = null
      this.country = null
      this.postalCode = null
      this.email = null
      this.phone = null
      this.whatsapp = null
      this.facebook = null
      this.instagram = null
      this.createdAt = null
      return
    }
    this.id = data.id
    this.tavernId = data.tavernId
    this.address = data.address
    this.state = data.state
    this.country = data.country
    this.postalCode = data.postalCode
    this.email = data.email
    this.phone = data.phone
    this.whatsapp = data.whatsapp
    this.facebook = data.facebook
    this.instagram = data.instagram
    this.createdAt = data.createdAt
  }
}
