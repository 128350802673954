<template>
  <ul class="navbar-nav">
    <li class="nav-item">
      <sideNavItem
        :url="`/private/user/${loggedUserId}/profile`"
        :nav-text="$t('messages.userData')"
      >
        <template #icon>
          <mdicon
            name="badge-account-horizontal-outline"
            class="text-success"
          />
        </template>
      </SideNavItem>
    </li>
    <li class="nav-item">
      <sideNavItem
        :url="`/${baseDir}/user/${loggedUserId}/taverns`"
        :nav-text="$t('messages.myTaverns')"
      >
        <template #icon>
          <mdicon
            name="store-outline"
            class="text-success"
          />
        </template>
      </SideNavItem>
    </li>
  </ul>
</template>

<script>
import SideNavItem from '@/components/layout/sideNav/SideNavItem.vue'

export default {
  name: 'PrivateSideNav',
  components: { SideNavItem },
}
</script>

<style>
</style>
