import {privateClient} from '@/plugins/axios'
import {buildUrlParams} from '@/utils/urlUtils'

export default class privateTagResource {
    static async createTag (tag) {
        try {
            return privateClient.post('/tag', tag)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateTag (tag) {
        try {
            return privateClient.patch(`/tag/${tag.id}`, tag)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteTag (tagId) {
        try {
            await privateClient.delete(`/tag/${tagId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getMenuItemTags (menuItemId) {
        try {
            const tags = await privateClient.get(`/menu-item/${menuItemId}/tags`)
            return tags.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getTags(params) {
        try {
            const tags = await privateClient.get('/tag/', { params: buildUrlParams(params) })
            return {data: tags.data.data, total: tags.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
