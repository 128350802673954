<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <UpsertMenuItem
        v-if="showUpsert"
        :menu-item="menuItem"
        :menu-section-id="menuSectionId"
        @create-menu-item="createMenuItem"
        @update-menu-item="updateMenuItem"
        @hide-forms="hideForms"
      />
    </div>
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <a
              class="btn btn-link btn-sm text-black-30"
              :title="$t('messages.back')"
              @click="$router.go(-1)"
            >
              <mdicon name="arrow-left" />
              {{ $t('messages.back') }}
            </a>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-start">
              <h6 class="mb-2">
                {{ $t('messages.myMenuItems') }}
              </h6>
            </div>
            <div class="col d-flex align-items-center ">
              <button
                v-if="!showUpsert"
                class="btn mb-0 btn-success ms-auto"
                @click="enableCreate"
              >
                <mdicon name="invoice-text-plus-outline" />
                {{ $t('messages.createMenuItem') }}
              </button>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.menuItemName') }}
                </p>
              </div>
            </div>
          </div>
          <Draggable
            v-model="menuItems"
            tag="div"
            item-key="id"
            class="table"
            @end="moved($event, menuItems)"
          >
            <template #item="{ element: currentMenuItem }">
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ currentMenuItem.title }}
                    </h6>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a
                      class="btn btn-link text-success"
                      :title="$t('messages.editMenuItem')"
                      @click="selectItem(currentMenuItem)"
                    >
                      <mdicon name="invoice-text-edit-outline" />
                    </a>
<!--                    <a
                      class="btn btn-link text-success"
                      :href="`/${baseDir}/menu-item/${currentMenuItem.id}/tags`"
                      :title="$t('messages.seeMenuItemTags')"
                    >
                      <mdicon name="tag-multiple-outline" />
                    </a>-->
                    <a
                      class="btn btn-link text-danger"
                      :title="$t('messages.deleteMenuItem')"
                      @click="deleteMenuItem(currentMenuItem)"
                    >
                      <mdicon name="invoice-text-remove-outline" />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </Draggable>
          <div class="d-flex justify-content-end p-4">
            <button
              v-if="showOrderButton()"
              class="btn mb-0 btn-info ms-auto"
              @click="updatePositions"
            >
              <mdicon name="sort" />
              {{ $t('messages.saveOrdering') }}
            </button>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
  </div>
</template>

<script>
import contentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import UpsertMenuItem from '@/views/components/menuItem/UpsertMenuItem.vue'
import Pagination from '@/components/common/Pagination.vue'
import privateMenuItemResource from '@/resources/menuItem/privateMenuItemResource'
import adminMenuItemResource from '@/resources/menuItem/adminMenuItemResource'
import MenuItem from '@/models/menuItem'
import Draggable from 'vuedraggable'

export default {
  name: 'MenuItemList',
  components: {
    Draggable,
    contentPlaceholder,
    UpsertMenuItem,
    Pagination
  },
  props: {
    menuSectionId: { type: String, required: true }
  },
  data () {
    return {
      loading: true,
      showUpsert: false,
      menuItems: null,
      total: 0,
      menuItem: null,
      paging: { page: 1, limit: 100, sort: 'position', sortType: 'asc' },
      showPagination: false,
      resource: null,
      orderedList: null
    }
  },
  mounted () {
    this.resource = this.admin ? adminMenuItemResource : privateMenuItemResource
    this.selectItems()
  },
  methods: {
    async selectItems () {
      await this.getMenuItems()
    },
    async getMenuItems () {
      const response = await this.resource.getMenuSectionMenuItems(this.menuSectionId, this.paging)
      if (response) {
        this.loading = false
        this.menuItems = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async createMenuItem (menuItemData) {
      try {
        const response = await this.resource.createMenuItem(menuItemData)
        if (!response) {
          this.$notice.alert.error(this.$t('messages.errorMessage'));
          return
        }
        this.appendItemOnList(response.data)
      } catch (e) {
        this.$notice.alert.error(this.$t('messages.errorMessage'));
      }
    },
    async updateMenuItem (menuItemData) {
      try {
        const response = await this.resource.updateMenuItem(menuItemData)
        if (!response) {
          this.$notice.alert.error(this.$t('messages.errorMessage'))
          return false
        }
        this.$notice.toast.info(this.$t('messages.updateMessage'))
        this.updateItemOnList(response.data)
      } catch (e) {
        this.$notice.alert.error(this.$t('messages.errorMessage'))
      }
    },
    async deleteMenuItem (menuItem) {
      if (confirm(this.$t('messages.areYouSure'))) {
        try {
          const response = await this.resource.deleteMenuItem(menuItem.id)
          if (!response) {
            this.$notice.alert.error(this.$t('messages.errorMessage'))
          }
          this.$notice.toast.success(this.$t('messages.deletedMessage'));
          this.removeItemFromList(menuItem)
        } catch (e) {
          this.$notice.alert.error(this.$t('messages.errorMessage'))
        }
      }
    },
    selectItem (menuItem) {
      this.menuItem = new MenuItem(menuItem)
      this.showUpsert = true
    },
    appendItemOnList (menuItem) {
      this.menuItems.unshift(menuItem)
      this.showUpsert = false
    },
    removeItemFromList (menuItem) {
      this.menuItems = this.menuItems.filter((item) => item.id !== menuItem.id)
      this.showUpsert = false
    },
    updateItemOnList (menuItem) {
      this.menuItems.find((item, index) => {
        if (item.id === menuItem.id) {
          this.menuItems[index] = menuItem
        }
      })
      this.showUpsert = false
    },
    enableCreate () {
      this.menuItem = new MenuItem()
      this.showUpsert = true
    },
    hideForms () {
      this.showUpsert = false
    },
    setPage (page) {
      this.paging.page = page
      this.selectItems()
    },
    showOrderButton() {
      return !!this.orderedList
    },
    moved (evt, updatedList) {
      this.orderedList = updatedList
    },
    async updatePositions() {
      if (!this.orderedList) {
        return;
      }
      try {
        for (const key in this.orderedList) {
          const item = this.orderedList[key]
          item.position = key
          await this.resource.updateMenuItem(item)
        }
        this.orderedList = null
      } catch (e) {
        this.$notice.alert.error(this.$t('messages.errorMessage'))
      }
    }
  }
};
</script>

<style scoped>
.table > :not(:first-child) {
  border: none;
}
.table-row  {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
.title {
  padding-left: 8px;
}
</style>
