import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const alertOptions = { queue: true, duration: 0, position: 'top' }
const toastOptions = { duration: 5000, position: 'top-right' }

const toast = useToast()

const notice = {
    alert: {
        default (msg) {
            return toast.default(msg, alertOptions)
        },
        success (msg) {
            return toast.success(msg, alertOptions)
        },
        error (msg) {
            return toast.error(msg, alertOptions)
        },
        warning (msg) {
            return toast.warning(msg, alertOptions)
        },
        info (msg) {
            return toast.info(msg, alertOptions)
        }
    },
    toast: {
        default (msg) {
            return toast.default(msg, toastOptions)
        },
        success (msg) {
            return toast.success(msg, toastOptions)
        },
        error (msg) {
            return toast.error(msg, toastOptions)
        },
        warning (msg) {
            return toast.warning(msg, toastOptions)
        },
        info (msg) {
            return toast.info(msg, toastOptions)
        },
        dismissAll () {
            toast.clear()
            return this
        },
    },

}

export default {
    install: (App) => {
        App.config.globalProperties.$notice = notice
    }
}

