<template>
  <SigninForm
    v-if="!isLogged()"
    @signin-user="signinUser"
  />
</template>

<script>

import PrivateUserResource from '@/resources/user/privateUserResource'
import {mapGetters, mapMutations} from 'vuex'
import SigninForm from '@/views/components/signin/SigninForm.vue'

export default {
  name: 'SigninPage',
  components: { SigninForm },
  methods: {
    ...mapMutations(['setUser', 'setToken']),
    ...mapGetters(['isAdmin', 'isLogged']),
    async signinUser(loginData) {
      const signedIn = await PrivateUserResource.signin(loginData)

      if (!signedIn) {
        this.$notice.alert.error(this.$t('messages.errorMessage'));
        return
      }

      const { user, token } = signedIn;
      if (!user || !token) {
        this.$notice.alert.error(this.$t('messages.errorMessage'))
        return
      }

      this.setUser(user)
      this.setToken(token)

      if (this.isAdmin()) {
        window.location = 'admin/users'
        return
      }
      window.location = `/private/user/${user.id}/profile`
    }
  }
}
</script>
