<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <UpsertTag
        v-if="showUpsert"
        :tag="tag"
        @create-tag="createTag"
        @update-tag="updateTag"
        @hide-forms="hideForms"
      />
    </div>
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <div class="col-lg-8 p-3 d-flex justify-content-between">
              <h6 class="mb-2">
                {{ $t('messages.tags') }}
              </h6>
            </div>
            <div class="col-lg-4 d-flex align-items-center mr-4">
              <button
                v-if="!showUpsert"
                class="btn mb-0 btn-success ms-auto"
                @click="enableCreate"
              >
                <mdicon name="tag-plus-outline" />
                {{ $t('messages.createTag') }}
              </button>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.tagName') }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              v-for="currentTag in tags"
              :key="currentTag.id"
            >
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ currentTag.name }}
                    </h6>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a
                      class="btn btn-link btn-sm text-success"
                      :title="$t('messages.editTag')"
                      @click="selectItem(currentTag)"
                    >
                      <mdicon name="tag-edit-outline" />
                    </a>
                    <a
                      class="btn btn-link btn-sm text-success"
                      :href="`/${baseDir}/tag/${currentTag.id}/menu-items`"
                      :title="$t('messages.tagRelatedMenuItems')"
                    >
                      <mdicon name="invoice-text-multiple-outline" />
                    </a>
                    <a
                      class="btn btn-link text-danger"
                      :title="$t('messages.deleteTag')"
                      @click="deleteTag(currentTag)"
                    >
                      <mdicon name="tag-remove-outline" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
  </div>
</template>

<script>
import ContentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import Pagination from '@/components/common/Pagination.vue'
import store from '@/store'
import UpsertTag from '@/views/components/tag/UpsertTag.vue'
import adminTagResource from "@/resources/tag/adminTagResource";
import Tag from "@/models/tag";
import privateTagResource from "@/resources/tag/privateTagResource";

export default {
  name: "TagList",
  components: {
    ContentPlaceholder,
    UpsertTag,
    Pagination
  },
  data() {
    return {
      loading: true,
      showUpsert: false,
      tags: null,
      total: 0,
      emptyTag: new Tag(),
      tag: null,
      paging: { page: 1, limit: 20, sort: 'name', sortType: 'desc' },
      showPagination: false,
      resource: null
    }
  },
  mounted () {
    this.resource = this.admin ? adminTagResource : privateTagResource
    this.selectItems()
  },
  methods: {
    async selectItems () {
      if (store.getters.isAdmin) {
        await this.getTags()
      }
    },
    async getTags () {
      const response = await this.resource.getTags(this.paging)
      if (response) {
        this.loading = false
        this.tags = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async createTag (tagData) {
      try {
        const response = await this.resource.createTag(tagData)
        if (!response) {
          this.$notice.alert.error(this.$t('messages.errorMessage'));
          return
        }
        this.appendItemOnList(response.data)
      } catch (e) {
        this.$notice.alert.error(this.$t('messages.errorMessage'));
      }
    },
    async updateTag (tagData) {
      try {
        const response = await this.resource.updateTag(tagData)
        if (!response) {
          this.$notice.alert.error(this.$t('messages.errorMessage'))
          return false
        }
        this.$notice.toast.info(this.$t('messages.updateMessage'))
        this.updateItemOnList(response.data)
      } catch (e) {
        this.$notice.alert.error(this.$t('messages.errorMessage'))
      }
    },
    async deleteTag (tag) {
      if (confirm(this.$t('messages.areYouSure'))) {
        try {
          const response = await this.resource.deleteTag(tag.id)
          if (!response) {
            this.$notice.alert.error(this.$t('messages.errorMessage'))
          }
          this.$notice.toast.success(this.$t('messages.deletedMessage'));
          this.removeItemFromList(tag)
        } catch (e) {
          this.$notice.alert.error(this.$t('messages.errorMessage'))
        }
      }
    },
    selectItem (tag) {
      this.tag = new Tag(tag)
      this.showUpsert = true
    },
    appendItemOnList (newTag) {
      this.tags.unshift(newTag)
      this.showUpsert = false
    },
    removeItemFromList (tag) {
      this.tags = this.tags.filter((item) => item.id !== tag.id)
      this.showUpsert = false
    },
    updateItemOnList (tag) {
      this.tags.find((item, index) => {
        if (item.id === tag.id) {
          this.tags[index] = tag
        }
      })
      this.showUpsert = false
    },
    enableCreate () {
      this.tag = new Tag()
      this.showUpsert = true
    },
    hideForms () {
      this.showUpsert = false
    },
    setPage (page) {
      this.paging.page = page
      this.selectItems()
    }
  }
};
</script>

<style scoped>
.table :not(:first-child) {
  border: none;
}
.table-row {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
</style>
