const  UserStatus = {
    incoming: 'INCOMING',
    active: 'ACTIVE',
    inactive: 'INACTIVE'
}

const UserType = {
    none: 'NONE',
    admin: 'ADMIN',
    user: 'USER'
}

export {
    UserStatus,
    UserType
}
