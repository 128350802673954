import { createApp } from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'
import GlobalMixin from '@/mixins/globalMixin'
import i18n from './plugins/i18n'
import notice from './plugins/notice'
import './assets/css/nucleo-icons.css'
import './assets/css/nucleo-svg.css'
import Layout from './layout'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

const appInstance = createApp(App)
appInstance.use(store)
appInstance.use(router)
appInstance.use(Layout)
appInstance.use(i18n)
appInstance.use(notice)
appInstance.use(mdiVue, { icons: mdijs })
appInstance.mixin(GlobalMixin)
appInstance.mount('#app')
