import {MenuStatus} from "@/utils/enums/menuEnum";

export default class Menu {
    constructor (data) {
        if (!data) {
            this.id = null
            this.tavernId = null
            this.title = null
            this.description = null
            this.footNote = null
            this.image = null
            this.createdAt = null
            this.status = MenuStatus.active
            return
        }
        this.id = data.id
        this.tavernId = data.tavernId
        this.title = data.title
        this.description = data.description
        this.footNote = data.footNote
        this.image = data.image
        this.createdAt = data.createdAt
        this.status = data.status
    }
}
