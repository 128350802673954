<template>
  <router-link
    :to="url"
    class="nav-link text-success"
    v-bind="$attrs"
  >
    <div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center">
      <slot name="icon" />
    </div>
    <span class="nav-link-text ms-1">{{ navText }}</span>
  </router-link>
</template>
<script>
export default {
  name: 'SideNavItem',
  props: {
    url: {
      type: String,
      required: true
    },
    navText: {
      type: String,
      required: true
    }
  }
};
</script>
