import {MenuItemStatus} from "@/utils/enums/menuItemEnum";

export default class MenuItem {
    constructor (data) {
        if (!data) {
            this.id = null
            this.menuSectionId = null
            this.title = null
            this.description = null
            this.image = null
            this.price = null
            this.currency = null
            this.createdAt = null
            this.position = null
            this.status = MenuItemStatus.active
            return
        }
        this.id = data.id
        this.menuSectionId = data.menuSectionId
        this.title = data.title
        this.description = data.description
        this.image = data.image
        this.price = data.price
        this.currency = data.currency
        this.createdAt = data.createdAt
        this.position = data.position
        this.status = data.status
    }
}