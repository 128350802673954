<template>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <UserList />
  </main>
</template>

<script>
import UserList from '@/views/components/user/UserList.vue'
export default {
  name: 'AdminUsersPage',
  components: {
    UserList
  }
}
</script>