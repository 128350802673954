<template>
  <navBar
    v-if="showBars()"
  />
  <sideNav
    v-if="showBars()"
  />
  <router-view />
</template>

<script>
import sideNav from '@/components/layout/SideNav.vue'
import navBar from '@/components/layout/NavBar.vue'

export default {
  name: 'App',
  components: { navBar, sideNav },
  methods: {
    showBars () {
      if (['RecoverPassword', 'ResetPassword'].includes(this.$route.name)) {
        return false
      }
      return this.logged
    }
  }
}
</script>

<style scoped>
</style>
