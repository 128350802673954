<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="menuItem.id">
        {{ $t('messages.editMenuItem') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createMenuItem') }}
      </h6>
    </div>
    <div class="card-body">
      <ValidationForm
        v-slot="{errors}"
        :validation-schema="menuItemSchema"
        type="POST"
        @submit="upsertMenuItem"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.menuItemName') }}</label>
          <Field
            v-model="menuItemTitle"
            name="name"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.menuItemName}"
            :placeholder="$t('messages.menuItemName')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.menuItemDescription') }}</label>
          <TextEditor
            ref="description"
            :content="menuItem.description"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.menuItemPrice') }}</label>
          <Field
            v-model="menuItemPrice"
            name="price"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.menuItemPrice}"
            :placeholder="$t('messages.menuItemPrice')"
            aria-label="Price"
          />
          <ErrorMessage
            class="text-danger small"
            name="price"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.statusSelect') }}</label>
          <Field
            v-model="menuItemStatus"
            name="status"
            as="select"
            class="form-control"
            :value="menuItem.status"
            :class="{'is-invalid':errors.menuItemStatus}"
            :placeholder="$t('messages.statusSelect')"
            aria-label="Status"
          >
            <option
              v-for="item in statusList"
              :key="item"
              :value="item"
            >
              {{ $t('messages.status.' + item) }}
            </option>
          </Field>
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              type="submit"
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              &nbsp;{{ $t('messages.save') }}
            </button>
            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </ValidationForm>
    </div>
  </div>
</template>
<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import {lazy, number, object, string} from 'yup'
import { MenuItemStatus } from '@/utils/enums/menuItemEnum.js'
import MenuItem from '@/models/menuItem'
import TextEditor from '@/components/common/TextEditor.vue'

export default {
  name: 'UpsertMenuItem',
  components: { TextEditor, ValidationForm, ErrorMessage, Field },
  props: {
    menuItem: MenuItem,
    menuSectionId: { type: String, required: true }
  },
  emits: ['create-menu-item', 'update-menu-item', 'hide-forms'],
  data () {
    const floatMessage = this.$t('messages.errors.floatError')
    const menuItemSchema = object({
      name: string().required(this.$t('messages.errors.requiredField')),
      status: string().optional(),
      price: lazy((value) => {
        if (value !== '' && isNaN(value)) {
          return number().typeError(floatMessage)
        }
        if (!value || value === '' || value === undefined) {
          return  string().optional().nullable()
        }
        return number()
      })
    })
    return {
      menuItemSchema,
      statusList: MenuItemStatus
    }
  },
  computed: {
    menuItemTitle: {
      get: function () {
        return this.menuItem.title
      },
      set: function (title) {
        return title
      }
    },
    menuItemPrice: {
      get: function () {
        return this.menuItem.price
      },
      set: function (description) {
        return description
      }
    },
    menuItemStatus: {
      get: function () {
        return this.menuItem.status ? this.menuItem.status : null
      },
      set: function (status) {
        return status
      }
    },
  },
  methods: {
    async upsertMenuItem (menuItemData) {
      if (!this.menuItem.id) {
        await this.createMenuItem(menuItemData)
        return
      }
      await this.updateMenuItem(menuItemData)
    },
    async createMenuItem (menuItemData) {
      let menuItem = {
        title: menuItemData.name,
        description: this.$refs.description.getContent(),
        menuSectionId: this.menuSectionId,
        position: '0',
        price: menuItemData.price ? menuItemData.price.toString() !== '' ? menuItemData.price.toString() : null : null,
        currency: 'EUR'
      }
      this.$emit('create-menu-item', menuItem)
    },
    async updateMenuItem (menuItemData) {
      let menuItem = {
        id: this.menuItem.id,
        title: menuItemData.name,
        description: this.$refs.description.getContent(),
        status: menuItemData.status,
        price: menuItemData.price
      }
      this.$emit('update-menu-item', menuItem)
    },
    cancel () {
      this.$emit('hide-forms')
    }
  }
}
</script>
