import { privateClient } from '@/plugins/axios'
import { buildUrlParams } from '@/utils/urlUtils'

export default class privateTavernResource {
    static async createTavern (tavern) {
        try {
            return privateClient.post('/tavern', tavern)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async updateTavern (tavern) {
        try {
            return privateClient.patch(`/tavern/${tavern.id}`, tavern)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async deleteTavern (tavernId) {
        try {
            await privateClient.delete(`/tavern/${tavernId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    static async getUserTaverns (userId, params) {
        try {
            const taverns = await privateClient.get(`/user/${userId}/taverns`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
