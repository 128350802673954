<template>
  <ul class="navbar-nav me-0 ms-auto">
    <li class="nav-item">
      <span class="nav-link text-white text-sm">
        <mdicon
          name="account-circle"
          class="top-icon"
        />
        {{ $t('messages.helloUser', { name }) }}
      </span>
    </li>
    <li class="nav-item ml-1">
      <a
        role="button"
        class="nav-link text-danger power-button"
        @click="signOut"
      >
        <mdicon
          name="power"
          class="text-danger"
        />
        &nbsp;{{ $t('messages.signOut') }}
      </a>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'TopNavBar',
  data() {
    return {
      name: this.getUser().name
    }
  }
}
</script>

<style>
.power-button {
  margin-left:50px;
}
</style>
